<template>
    <v-card v-if="showBanner" class="banner">
        <h1>
            This website uses cookies.
        </h1>
        <v-btn @click="acceptCookies" class="mr-2" color="white">
            <h2>
                Accept
            </h2>
        </v-btn>
        <v-btn @click="declineCookies">
            <h2>
                Decline
            </h2>
        </v-btn>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            showBanner: false,
        };
    },
    created() {
        this.checkCookie();
    },
    methods: {
        acceptCookies() {
            this.setCookie('acceptedCookies', 'true', 365);
            this.setCookie('declinedCookies', '', -1);  // Remove declined cookie if it exists
            this.showBanner = false;
        },
        declineCookies() {
            this.setCookie('declinedCookies', 'true', 365);
            this.setCookie('acceptedCookies', '', -1);  // Remove accepted cookie if it exists
            this.showBanner = false;
        },
        setCookie(name, value, days) {
            const d = new Date();
            d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
            const expires = "expires=" + d.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        },
        getCookie(name) {
            const nameEQ = name + "=";
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        checkCookie() {
            const acceptedCookies = this.getCookie('acceptedCookies');
            const declinedCookies = this.getCookie('declinedCookies');
            if (!acceptedCookies && !declinedCookies) {
                this.showBanner = true;
            }
        }
    }
};
</script>

<style scoped>
h1 {
    font-family: "KyivType Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24.8px;
    color: white;
    margin-bottom: 10px
}

h2 {
    font-family: "KyivType Sans";
    font-size: 12px !important;
    font-weight: 900 !important;
    line-height: 18px !important;
    text-align: left;
    color: #3A61ED
}

.banner {
    background-color: #3A61ED;
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 5%;
    left: 5%;
    z-index: 9999;
    width: 90%;
}
</style>