<template>
    <div class="locale-switcher">
        <div class="locale-changer mr-5" style="display:flex; align-items:center">
            <v-btn @click="$i18n.locale = 'en'; setLocaleLang('en')" icon class="r-0" style="width: 40px !important;">
                <h1>
                    EN
                </h1>
            </v-btn>
            <h1>|</h1>
            <v-btn @click="$i18n.locale = 'de'; setLocaleLang('de')" icon class="pl-0" style="width: 40px !important;">
                <h1>
                    DE
                </h1>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        setLocaleLang(lang) {
            localStorage.setItem("lang", lang)
        }
    }
}
</script>

<style>
h1 {
    font-family: "KyivType Sans";
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    color: white
}

@media (max-width: 900px) {
    h1 {
        font-family: "KyivType Sans";
        font-size: 12px !important;
        font-weight: 900 !important;
        line-height: 18px !important;
        text-align: left;
        color: white
    }
}
</style>