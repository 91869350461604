import { createApp } from 'vue'
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import cookieconsent from 'vue-cookieconsent-component'
import i18n from './i18n'

const vuetify = createVuetify({
  components,
  directives,
})

createApp(App).use(router).use(i18n).use(vuetify).component('cookie-consent', cookieconsent).mount('#app')

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import router from './router'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCB0QbhYa-WuAdshNoW9aCyl53QrxpUzE",
  authDomain: "amplifi-64dbb.firebaseapp.com",
  projectId: "amplifi-64dbb",
  storageBucket: "amplifi-64dbb.appspot.com",
  messagingSenderId: "783646925109",
  appId: "1:783646925109:web:0c0595f059671f9cc16c7c",
  measurementId: "G-PZ253NLHEV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);