<template>
  <v-container>
    <div style="position: relative; width: 728px; height: 735px; margin: auto" :style="currBP == 'sm' ||  currBP == 'xs' ? 'transform: scale(0.5);  width: 328px;': ''">      
      <div style="z-index: 90px !important; position:absolute; top: 230px; right: 90px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('tambour')" @mouseenter="switchRoom('tambour')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 290px; right: 100px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('sanitary')" @mouseenter="switchRoom('sanitary')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 350px; right: 100px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('storage')" @mouseenter="switchRoom('storage')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 350px; right: 520px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('corridor')" @mouseenter="switchRoom('corridor')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>
      
      <div style="z-index: 90px !important; position:absolute; top: 120px; right: 560px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('food')" @mouseenter="switchRoom('food')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>
      
      <div style="z-index: 90px !important; position:absolute; top: 130px; right: 630px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('clothes')" @mouseenter="switchRoom('clothes')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>
      
      <div style="z-index: 90px !important; position:absolute; top: 605px; right: 395px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('toi')" @mouseenter="switchRoom('toi')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 605px; right: 360px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('let')" @mouseenter="switchRoom('let')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 605px; right: 475px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('store')" @mouseenter="switchRoom('store')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>


      <div style="z-index: 90px !important; position:absolute; top: 605px; right: 435px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('water')" @mouseenter="switchRoom('water')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 570px; right: 165px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('heat')" @mouseenter="switchRoom('heat')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top:275px; right: 300px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('main1')" @mouseenter="switchRoom('main1')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 205px; right: 600px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('main2')" @mouseenter="switchRoom('main2')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="z-index: 90px !important; position:absolute; top: 505px; right: 600px;" class="dot-container" :class="currBP == 'sm' ||  currBP == 'xs' ? 'moveRight': ''">
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom('main3')" @mouseenter="switchRoom('main3')" @mouseleave="switchRoom('def')"
          >
        </v-btn>
      </div>

      <div style="display:flex; justify-content: center; transform: scale(0.8); position: relative; z-index: 10 !important" >
        <img :src="currRoom" alt="" style="z-index: 10 !important">
      </div>
      <div v-for="room in allRooms" :key="room" style="width:100%">
        <img :src="require('@/assets/2/d-'+room+'-'+ $i18n.locale + '.svg')" alt="" style="transform: translate(0px,20px); scale: 1.5;" :style="{display: room===currRoomName ? 'block': 'none'}">
      </div>

    </div>
  </v-container>
</template>

<script>
import 'animate.css';

export default {
  computed: {
    currBP() {
      if (this.$vuetify.display['sm']) {
        return 'sm'
      } else if (this.$vuetify.display['xs']) {
        return 'xs'
      } else if (this.$vuetify.display['md']) {
        return 'md'
      } else if (this.$vuetify.display['lg']) {
        return 'lg'
      } else {
        return 'def'
      }
    },
    allRooms(){
      return Object.keys(this.rooms).slice(1,15)
    }
  },
  data() {
    return {
      rooms: { 
      def: require("@/assets/2/plan.svg"),
      tambour: require("@/assets/2/plan-tambour.svg"), 
      sanitary:  require("@/assets/2/plan-sanitary.svg"), 
      storage:  require("@/assets/2/plan-storage.svg"), 
      corridor:  require("@/assets/2/plan-corridor.svg"), 
      food:  require("@/assets/2/plan-food.svg"), 
      clothes:  require("@/assets/2/plan-clothes.svg"), 
      toi:  require("@/assets/2/plan-toi.svg"), 
      let:  require("@/assets/2/plan-let.svg"), 
      store:  require("@/assets/2/plan-store.svg"), 
      water:  require("@/assets/2/plan-water.svg"), 
      heat:  require("@/assets/2/plan-heat.svg"), 
      main1:  require("@/assets/2/plan-main1.svg"), 
      main2:  require("@/assets/2/plan-main2.svg"), 
      main3:  require("@/assets/2/plan-main3.svg"), 
    },
      currRoom: require("@/assets/2/plan.svg"),
      currRoomName: '',
      hoveredRoomId: null,
    };
  },
  methods: {
    switchRoom(room){
      console.log("sw", room)
      this.currRoom = this.rooms[room];
      this.currRoomName = room;
    }
  },
};
</script>

<style>
.dot {
  width: 18px !important;
  height: 18px !important;
  background-color: transparent;
  opacity: 0.7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999 !important;
  background-color: red;
}

.dot-icon {
  font-size: 10px;
  width: 23px !important;
  height: 13px !important;
}

.dot-container .dot-d{
  opacity:0%;
  z-index: 100 !important;
}
.dot-container{
  z-index: 90 !important;
}
.dot:hover ~ .dot-d {
  opacity:100%
}

.moveRight{
  transform: translateX(200px);
  z-index: 100 !important;
}
</style>