<template>
  <v-container>
    <div style="position: relative; width: 728px; height: 735px; margin: auto" :style="currBP == 'sm' || currBP == 'xs' ? 'transform: scale(0.5);  width: 328px;' : ''">
      <div v-for="room in roomPositions" :key="room.name" :style="'position:absolute; top: ' + room.top + ';right: ' + room.right" class="dot-container">
        <!-- Ensure button has a higher z-index -->
        <v-btn icon class="dot animate__animated animate__heartBeat animate__infinite" @click="switchRoom(room.name)" @mouseenter="switchRoom(room.name)" @mouseleave="switchRoom('def')" style="z-index: 10;"></v-btn>
        <!-- Image with pointer-events:none -->
        <img :src="require('@/assets/2/d-' + room.name + '-' + $i18n.locale + '.svg')" alt="" class="dot-d" :style="'transform: ' + room.transform + '; pointer-events: none;'">
      </div>

      <div style="display:flex; justify-content: center; transform: scale(0.8); position: relative; z-index: 10 !important;">
        <img :src="currRoom" alt="" style="z-index: 10 !important">
      </div>
    </div>
  </v-container>
</template>

<script>
import 'animate.css';

export default {
  computed: {
    currBP() {
      if (this.$vuetify.display['sm']) {
        return 'sm';
      } else if (this.$vuetify.display['xs']) {
        return 'xs';
      } else if (this.$vuetify.display['md']) {
        return 'md';
      } else if (this.$vuetify.display['lg']) {
        return 'lg';
      } else {
        return 'def';
      }
    }
  },
  data() {
    return {
      rooms: { 
        def: require("@/assets/2/plan.svg"),
        tambour: require("@/assets/2/plan-tambour.svg"), 
        sanitary:  require("@/assets/2/plan-sanitary.svg"), 
        storage:  require("@/assets/2/plan-storage.svg"), 
        corridor:  require("@/assets/2/plan-corridor.svg"), 
        food:  require("@/assets/2/plan-food.svg"), 
        clothes:  require("@/assets/2/plan-clothes.svg"), 
        toi:  require("@/assets/2/plan-toi.svg"), 
        let:  require("@/assets/2/plan-let.svg"), 
        store:  require("@/assets/2/plan-store.svg"), 
        water:  require("@/assets/2/plan-water.svg"), 
        heat:  require("@/assets/2/plan-heat.svg"), 
        main1:  require("@/assets/2/plan-main1.svg"), 
        main2:  require("@/assets/2/plan-main2.svg"), 
        main3:  require("@/assets/2/plan-main3.svg"), 
      },
      roomPositions: [
        { name: 'tambour', top: '230px', right: '-85px', image: 'd-tambour-', transform: 'translate(10px,-100px)' },
        { name: 'sanitary', top: '290px', right: '-90px', image: 'd-sanitary-', transform: 'translate(60px,-100px)' },
        { name: 'storage', top: '350px', right: '-55px', image: 'd-storage-', transform: 'translate(60px,-100px)' },
        { name: 'corridor', top: '350px', right: '340px', image: 'd-corridor-', transform: 'translate(60px,-100px)' },
        { name: 'food', top: '120px', right: '225px', image: 'd-food-', transform: 'translate(-50px,-180px)' },
        { name: 'clothes', top: '130px', right: '410px', image: 'd-clothes-', transform: 'translate(-250px,-100px)' },
        { name: 'toi', top: '605px', right: '235px', image: 'd-toi-', transform: 'translate(-50px,30px)' },
        { name: 'let', top: '605px', right: '50px', image: 'd-let-', transform: 'translate(-200px,50px)' },
        { name: 'store', top: '605px', right: '222px', image: 'd-store-', transform: 'translate(-200px,50px)' },
        { name: 'water', top: '605px', right: '183px', image: 'd-water-', transform: 'translate(-200px,70px)' },
        { name: 'heat', top: '575px', right: '-100px', image: 'd-heat-', transform: 'translate(-200px,70px)' },
        { name: 'main1', top: '275px', right: '50px', image: 'd-main1-', transform: 'translate(-100px,-350px)' },
        { name: 'main2', top: '205px', right: '420px', image: 'd-main2-', transform: 'translate(-250px,-50px)' },
        { name: 'main3', top: '505px', right: '420px', image: 'd-main3-', transform: 'translate(-250px,-150px)' }
      ],
      currRoom: require("@/assets/2/plan.svg"),
      hoveredRoomId: null,
    };
  },
  methods: {
    switchRoom(room){
      console.log("sw", room)
      this.currRoom = this.rooms[room];
    }
  },
};
</script>

<style>
.dot {
  width: 18px !important;
  height: 18px !important;
  background-color: transparent;
  opacity: 0.7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  pointer-events: all !important;
}

.dot-icon {
  font-size: 10px;
  width: 23px !important;
  height: 13px !important;
}

.dot-container{
  pointer-events:none; 
}

.dot-container .dot-d {
  opacity: 0;
  pointer-events: none; /* Prevent image from capturing hover events */
}

.dot:hover ~ .dot-d {
  opacity: 100%;
}

.moveRight {
  transform: translateX(200px);
}
</style>
